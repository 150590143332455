import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Many thanks to `}<a parentName="p" {...{
        "href": "http://www.linkedin.com/in/andrewgillen"
      }}>{`Andrew Gillen`}</a>{` of MasterCard for touting some of our recent successes with PayPerks on the Direct Express card. Articles in `}<a parentName="p" {...{
        "href": "http://paybefore.com/aboutus/default.aspx?id=30784"
      }}>{`Paybefore`}</a>{` and `}<a parentName="p" {...{
        "href": "http://bankcreditnews.com/news/mastercard-comerica-bank-financial-education-program-attracts-50000-federal-benefit-cardholders/11073/"
      }}>{`Bank Credit News`}</a>{` highlight PayPerks as an important tool for driving meaningful and beneficial changes in financial behavior.`}</p>
    <p>{`The articles also cite some comments from our `}<a parentName="p" {...{
        "href": "https://www.facebook.com/payperks"
      }}>{`Facebook page`}</a>{` which illustrate PayPerks' warm reception from Direct Express cardholders:`}</p>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul"><em parentName="li">{`“I use PayPerks and it is a very good way to learn how to use your Direct Express card.”`}</em></li>
        <li parentName="ul"><em parentName="li">{`“I really like the program! Thank you!”`}</em></li>
        <li parentName="ul"><em parentName="li">{`“Great concept. I am having fun learning how to save!”`}</em></li>
        <li parentName="ul"><em parentName="li">{`"What a great program. . . this would be a great thing for students who are in college and managing their own money for the first time!”`}</em></li>
        <li parentName="ul"><em parentName="li">{`“Love this. Very Educational. Love the chance to win money.”`}</em></li>
        <li parentName="ul"><em parentName="li">{`“This is ingenious! The earn-while-you-learn concept is awesome. Even an absolute newbie can learn how to make the most of their money with this setup.”`}</em></li>
      </ul>
    </blockquote>
    <p>{`Like us on `}<a parentName="p" {...{
        "href": "https://www.facebook.com/payperks"
      }}>{`Facebook`}</a>{` or follow us on `}<a parentName="p" {...{
        "href": "https://twitter.com/PayPerks"
      }}>{`Twitter`}</a>{` to join the conversation!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      